body {
  width: -webkit-fill-available;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -o-flex-direction: column;
}

header {
  text-align: center;
  flex-grow: 0;
}

#head {
  background-image: linear-gradient(to top, #537895 0%, #09203f 100%);
}

#nav .item {
  display: inline-block;
  width: -webkit-fill-available;
}

h1 {
  display: inline-block;
}

#title {
  padding: 5rem 0;
}

#menubtn {
  margin-top: 3rem;
}

main {
  flex-basis: auto;
  flex-grow: 1;
  padding: 2rem 0;
  background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);
  background-blend-mode: screen;
}

main .container{
  height: 100%;
  display: flex !important;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -o-flex-direction: column;
}

main .segment {
  flex-grow: 1;
  margin: 0 !important;
}

.segment h4{
  padding-bottom: .5rem !important;
}

footer {
  flex-grow: 0
}

.footer {
  padding: 1rem 0;
  text-align: center;
  background-color: #ddd;
}

.card {
  padding: .5rem;
}

#seller .list {
  padding: 0 5rem;
}

@media screen and (max-width: 479px) {
  p {
      width: -webkit-fill-available;
      text-align: center;
      line-height: 1.5rem;
      word-wrap: break-word;
  }
}